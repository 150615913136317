import { cpexLog, addIframe } from '../utils.js'

/**
 * Simply wraps an HTML banner and scales it down if it's larger than the viewport.
 * It requires responsive settings (with a target elementId and size) to be present.
 */
export default class Responsive {
  constructor (elementId, settings, width, height) {
    this.type = 'responsive'
    this.loaded = false
    this.elementId = elementId
    this.settings = settings.formats.responsive
    this.publisher = settings.publisher.code
    this.width = width
    this.height = height
    this.iframeCSS = `width: ${width}px; max-width: ${width}px; height: ${height}px; max-height: ${height}px; margin:auto; border:none; display:block; overflow:hidden; background-color:#FFF; ` // Needed for HB reRender
    cpexLog('Responsive: Caught Responsive custom format, in elementId ' + elementId)
  }

  resize () {
    if (this.adWidth > document.documentElement.clientWidth) { // window inner width without scrollbar
      this.wrapper.style.transformOrigin = 'left top'
      const scaleFactor = this.contentWidth / this.adWidth
      this.wrapper.style.transform = 'scale(' + scaleFactor + ')' // scale down content
      this.wrapper.style.width = this.adWidth * scaleFactor + 'px'
      this.wrapper.style.height = this.adHeight * scaleFactor + 'px' // resize container height to match
    } else {
      this.wrapper.style.transform = 'scale(1)'
    }
  }

  /**
   * Places the responsive container into the page
   */
  prepareIframe (width, height) {
    this.element = document.getElementById(this.elementId)
    this.wrapper = document.createElement('div')
    this.wrapper.style.display = 'inline-block' // to remove gap below
    this.element.appendChild(this.wrapper)
    this.adWidth = width
    this.adHeight = height
    this.element.style.width = '100%' // make container full width, to account for padding
    this.element.style.display = 'block'
    if (this.publisher === 'eco') {
      this.contentWidth = window.innerWidth
    } else {
      this.contentWidth = this.element.clientWidth // save content width in pixels, before the large iframe is added
    }

    // Add iframe
    this.iframe = addIframe(this.wrapper, { id: this.elementId + '-iframe', width: this.width, height: this.height }, (iframe) => { // onload callback
      this.loaded = true
      this.resize()
      window.addEventListener('resize', () => this.resize())
      cpexLog('Responsive: Rendered')
    })
    this.iframe.style.cssText = this.iframeCSS
    return this.iframe
  }

  /**
   * Returns the page to its original state
   */
  reset () {
    this.element.removeChild(this.wrapper)
    const ads = window.cpexPackage.customAds
    if (ads) { delete ads[this.elementId] }
  }
}
