
import { addElement } from './utils'

export default class DSA {
  constructor (dsa, element) {
    this.data = dsa
    this.adElement = element
    this.modalOpen = false
    this.parameters = {}

    if (dsa.adrender === 0) {
      element.style.position = 'relative'
      // get domains for each parameter
      dsa.transparency.forEach((entity) => {
        [1, 2, 3].forEach(num => {
          if (entity.dsaparams.includes(num)) {
            this.parameters[num] = this.parameters[num] || []
            this.parameters[num].push(entity.domain)
          }
        })
      })
      setTimeout(() => { this.render(element) }, 100) // wait for ad to render
    }
  }

  render () {
    addElement('style', this.adElement, { innerHTML: style })
    this.wrapper = addElement('div', this.adElement, { class: 'cpexDSA' })
    this.iconEl = addElement('button', this.wrapper, { class: 'cpexDSA_icon info', innerHTML: infoIcon })
    this.iconEl.addEventListener('click', () => this.toggleModal())
  }

  toggleModal () {
    this.modalOpen ? this.closeModal() : this.openModal()
    this.modalOpen = !this.modalOpen
  }

  openModal () {
    this.iconEl.innerHTML = closeIcon
    this.iconEl.classList.remove('info')
    this.modalEl = addElement('div', this.wrapper, { class: 'cpexDSA_modal' })
    addElement('h3', this.modalEl, { innerHTML: 'O této reklamě' })
    const infoEl = addElement('table', this.modalEl, { class: 'cpexDSA_info' })
    if (this.data.behalf) {
      addElement('tr', infoEl, { innerHTML: `<td>Inzerent</td><td>${this.data.behalf}</td>` })
    }
    addElement('tr', infoEl, { innerHTML: `<td>Plátce</td><td>${this.data.paid}</td>` })

    addElement('h3', this.modalEl, { innerHTML: 'Použité cílení' })
    const paramsEl = addElement('ul', this.modalEl)

    if (this.parameters[1]) { addElement('li', paramsEl, { innerHTML: `Informace o tom, jaké stránky si prohlížíte. <span>(${this.parameters[1].join(', ')})</span>` }) }
    if (this.parameters[2]) { addElement('li', paramsEl, { innerHTML: `Informace o typu Vašeho zařízení nebo prohlížeče, IP adrese nebo přibližné poloze (dle IP adresy). <span>(${this.parameters[2].join(', ')})</span>` }) }
    if (this.parameters[3]) { addElement('li', paramsEl, { innerHTML: `Informace o Vaší poloze. <span>(${this.parameters[3].join(', ')})</span>` }) }
  }

  closeModal () {
    this.iconEl.innerHTML = infoIcon
    this.iconEl.classList.add('info')
    this.wrapper.removeChild(this.modalEl)
  }
}

const infoIcon = "<svg width='3' height='14'><ellipse fill='#fff' cx='1.5' cy='1.5' rx='1.5' ry='1.5' /><rect fill='#fff' height='8' width='3' y='6' rx='1.5' ry='1.5' /></svg>"
const closeIcon = "<svg width='14' height='14'><rect fill='#666' height='14' width='3' x='5.5' y='0' rx='1.5' ry='1.5' transform='rotate(45 7 7)' /><rect fill='#666' height='14' width='3' x='5.5' y='0' rx='1.5' ry='1.5' transform='rotate(-45 7 7)' /></svg>"

const style = `
  .cpexDSA, .cpexDSA_icon, .cpexDSA_modal {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999999;
  }
    .cpexDSA_icon {
      width: 20px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none !important;
      padding: 0px;
      cursor: pointer;
      z-index: 9999999;
    }
      .cpexDSA_icon:hover {
        transform: scale(1.2);
      }
      .cpexDSA_icon.info svg {
        filter: drop-shadow(0.5px 0.5px 1.5px #000A);
      }
    .cpexDSA_modal {
      width: 300px;
      padding: 20px;
      padding-top: 0px;
      box-sizing: border-box;
      background-color: #f5f5f5;
      font-size: 13px;
      box-shadow: 2px 2px 6px #0003;
      text-align: left;
    }
    .cpexDSA h3 {
      margin-bottom: 5px;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
    }
    .cpexDSA table {
      width: 100%;
    }
    .cpexDSA ul {
      padding: 0px 0px 0px 15px;
    }
    .cpexDSA li {
      margin-bottom: 5px;
    }
    .cpexDSA li span {
      color: #666;
      font-size: 12px;
    }
`
