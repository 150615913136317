import { cpexLog, addIframe, addStyle } from '../utils.js'

/**
 * Simply wraps an HTML banner and slides it from the bottom up, with a close button.
 * It requires slideup settings (with a target elementId) to be present.
 */
export default class Slideup {
  constructor (elementId, settings, width, height) {
    this.type = 'slideup'
    this.elementId = elementId
    this.settings = settings.formats.slideup
    this.loaded = false
    this.width = width
    this.height = height
    this.iframeCSS = 'margin:auto; border:none; display:block; overflow:hidden; background-color:#FFF; max-width: initial; ' // Needed for HB reRender
    cpexLog('Slideup: Caught Slideup custom format, in elementId ' + elementId)
  }

  resize () {
    const viewportWidth = document.documentElement.clientWidth || window.innerWidth
    if (this.adWidth > viewportWidth) {
      const scale = viewportWidth / this.adWidth
      this.wrapper.style.cssText = 'position: fixed; bottom: 0px; height: 0px; right: 0px;' // ?
      this.wrapper.style.transform = `translateY(${-this.adHeight}px)`
      this.wrapper.style.scale = scale
      this.wrapper.style.transformOrigin = 'right bottom'
      // this.wrapper.style.marginBottom = `-${height}px`
      this.closeButton.style.transform = 'scale(' + 1 / scale + ')' // inverse scale
      this.closeButton.style.transformOrigin = 'right bottom'
    } else {
      this.wrapper.style.cssText = this.wrapperCSS // ?
    }
  }

  /**
   * Places the slideup container into the page
   */
  prepareIframe (width, height) {
    this.adWidth = width
    this.adHeight = height || this.settings.emptyHeight
    this.iframeCSS += `width: ${width}px; height: ${height}px`
    const animationSpeed = this.settings.animationSpeed || 0.2
    const backgroundEl = this.settings.backgroundEl || document.body

    // Add css animation
    addStyle(`
      .cpex-slideup-open { transform: translateY(${-this.adHeight}px); transition: transform ${animationSpeed}s ease-out }
      .cpex-slideup-close { transform: translateY(${this.adHeight}px)!important; transition: transform ${animationSpeed}s ease-in }
    `)
    // Add main container element, zero height, for centering
    this.element = document.createElement('div')
    this.element.id = 'cpex-slideup'
    this.element.style.cssText = 'position: fixed; bottom: 0px; height: 0px; left: 0px; width: 100%;'
    backgroundEl.appendChild(this.element)

    // Wrap into another div, so that close button can be aligned with narrow creatives
    this.wrapper = document.createElement('div')
    this.wrapper.id = 'cpex-slideup-wrapper'
    this.wrapperCSS = `position: relative; margin: auto; margin-bottom: -${this.adHeight}px; width: ${this.adWidth}px`
    this.wrapper.style.cssText = this.wrapperCSS
    this.element.appendChild(this.wrapper)

    // Label the original element as moved, for tag rendering
    this.originalElement = document.getElementById(this.elementId)
    if (this.originalElement !== backgroundEl) {
      this.originalElement.setAttribute('data-target-id-moved', this.element.id)
      this.originalElement.style.display = 'none'
    }

    // Add iframe
    this.iframe = addIframe(this.wrapper, { id: this.elementId + '-iframe' }, (iframe) => { // onload callback
      this.loaded = true
      // Close button
      this.closeButton = document.createElement('span')
      this.closeButton.id = 'cpex-slideup-close'
      this.closeButton.tabIndex = 0
      this.closeButton.innerHTML = this.settings.closeTextHTML || defaultCloseTextHTML
      this.closeButton.onclick = () => this.close()
      this.closeButton.addEventListener('click', () => { this.close() })
      this.closeButton.addEventListener('keyup', (event) => { if (event.keyCode === 13 || event.keyCode === 32) { this.close() } })
      this.wrapper.appendChild(this.closeButton)
      // Style wrapper
      this.wrapper.classList.add('cpex-slideup-open')
      addStyle((typeof this.settings !== 'undefined' && this.settings.css) || defaultCSS)
      // Resizing
      this.resize()
      window.addEventListener('resize', () => this.resize())
      cpexLog('Slideup: Rendered')
    })
    this.iframe.style.cssText = this.iframeCSS
    return this.iframe
  }

  /**
   * Closes the slideup (triggers slide animation downwards), then removes the ad. Triggered by clicking the close button.
   */
  close () {
    window.dispatchEvent(new window.CustomEvent('cpexSlideupClosed', { detail: this }))
    this.wrapper.classList.add('cpex-slideup-close')
    this.wrapper.addEventListener('transitionend', () => { this.reset() })
  }

  /**
   * Returns the page to its original state
   */
  reset () {
    this.element.remove()
    const ads = window.cpexPackage.customAds
    if (ads) { delete ads[this.elementId] }
  }
}

const defaultCSS = `
  #cpex-slideup {
    z-index: 9999;
  }
  #cpex-slideup-wrapper {
    background-color: #FFF8;
  }
  #cpex-slideup-close {
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: -40px;
    right: 0px;
    height: 40px;
    padding: 10px;
    background-color: #0008;
    font-size: 12pt;
    color: #fff;
  }
  #cpex-slideup-close:hover {
    background-color: #000;
  }
  #cpex-slideup .debugTags {
    position: absolute !important;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 300px;
  }
`
const defaultCloseTextHTML = 'Zavřít&nbsp;&nbsp;✕'
